<template>
	<div>
		<div class="content">
			<!-- 修改密码 -->
			<div class="mb20 pageTitle"><b>修改密码</b></div>
			<div>
				<el-form class="form" label-width="120px" v-loading="loading">
					<el-form-item label="账号:">
						<span>{{account}}</span>
					</el-form-item>
					<el-form-item label="原密码:">
						<el-input prefix-icon="el-icon-lock" type="password" placeholder="请输入原密码" v-model="oldpassword">
						</el-input>
					</el-form-item>
					<el-form-item label="新密码:">
						<el-input prefix-icon="el-icon-lock" type="password" placeholder="请输入新密码" v-model="password">
						</el-input>
					</el-form-item>
					<el-form-item label="确认密码:">
						<el-input prefix-icon="el-icon-lock" type="password" placeholder="请再次输入密码"
							v-model="compassword"></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			var account = this.$local.get('bpcUserinfo').username;
			return {
				loading: false,
				account: account,
				oldpassword: "",
				password: "",
				compassword: ""
			}
		},
		created() {},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			onSubmit() {
				var t = this;
				if (isEmpty(this.oldpassword, "原密码不能为空")) {
					return
				}
				if (isEmpty(this.password, "新密码不能为空")) {
					return
				}
				if (this.password != this.compassword){
					this.$message({
						type: 'error',
						message: "两次输入的密码不一致"
					})
					return
				}
				let data = {
					password: this.$md5(this.password),
					old_password: this.$md5(this.oldpassword)
				}
				this.$api.setPassword(data).then(res=>{
					if(res.status == 1){
						this.$local.remove('bpcusername')
						this.$local.remove('bpctid')
						this.$local.remove('telephone')
						this.$local.remove('bpcUserinfo')
						this.$router.push({path:'/login'})
					}
					
				})
				
			}
		},
		watch: {},
	};
</script>

<style lang="scss">
	@import '@/style/variables.scss';

	.content {
		.pageTitle {
			background-color: $navSubColor;
			padding: 10px;
		}

		.tips {
			width: 460px;
		}

		.form {
			width: 460px;
		}
	}
</style>
